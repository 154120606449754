import React from 'react';
import styled from 'styled-components';
import { ControllerFieldState, ControllerRenderProps, FieldValues } from 'react-hook-form';
import { RadioButton } from '@naf/radio-button';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { ProductType } from '@dtp/membership-service-types';
import { defaultProduct } from '../index';
import useSelector from '../../../../../redux/typedHooks';
import { Spinner } from '../../../../../components/spinner/Spinner';
import { ProductID } from '../../../../../lib/ProductID';

export const ProductSelect = ({
  field: { value = defaultProduct, onChange },
  fieldState: { error },
}: {
  field: ControllerRenderProps<FieldValues, 'product'>;
  fieldState: ControllerFieldState;
}) => {
  const user = useSelector((state) => state.myMembership.customerInformation.data);
  const products = useSelector((state) => state.internationalDrivingPermit.data.eligibleProducts);
  const isUpdating = useSelector((state) => state.becomeAMember.isUpdating);
  const isMember = user?.membership?.isValid ?? false;
  const isNotMemberLabel = 'ikke medlem';
  const idpProductArray =
    products && (products[ProductID.Idp1Year] || products[ProductID.Idp3Year] || products[ProductID.Idp1and3Year])
      ? [products[ProductID.Idp1Year], products[ProductID.Idp3Year], products[ProductID.Idp1and3Year]]
      : null;

  if (error) return <Text variant={TextVariant.ArticleTextHeader}>{error.message}</Text>;

  if (!products && !isUpdating)
    return (
      <Text variant={TextVariant.ArticleTextHeader}>
        Oops, vi har problemer med motoren... Ta kontakt med kundesenteret hvis problemet fortsetter!
      </Text>
    );

  if (!products && isUpdating) return <Spinner />;

  const createLabel = (id: string) => {
    switch (id) {
      case ProductID.Idp1Year:
        return '1-årig (1949)';
      case ProductID.Idp3Year:
        return '3-årig (1964)';
      case ProductID.Idp1and3Year:
        return '1-årig og 3-årig';
      default:
        return null;
    }
  };

  return idpProductArray ? (
    <RadioButtonGroup>
      {idpProductArray.map(
        (product: ProductType) =>
          product &&
          typeof product !== 'undefined' && (
            <RadioButton
              data-testid="product-select"
              onChange={() => {
                onChange({
                  productId: product.productNumber,
                  amount: product.productAppPrice[1].itemCount.toFixed(0),
                  isLoggedIn: value.isLoggedIn,
                });
              }}
              variant="standard"
              checked={value.productId === product.productNumber}
              outline
              key={product.productNumber}
              label={createLabel(product.productNumber) || product.productName}
              message={`${product.productAppPrice[1].itemTotalPrice},- ${isMember ? '' : isNotMemberLabel}`}
              name="fullRadio"
              width="content"
            />
          ),
      )}
    </RadioButtonGroup>
  ) : (
    <Text variant={TextVariant.ArticleTextHeader}>
      Oops, vi har problemer med motoren... Ta kontakt med kundesenteret hvis problemet fortsetter!
    </Text>
  );
};

const RadioButtonGroup = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: ${spacing.space24};

  > div:not(last-child) {
    margin-bottom: ${spacing.space8};
  }

  @media (min-width: ${breakpoints.m}) {
    > div {
      width: 50%;
    }
  }

  @media (min-width: ${breakpoints.xl}) {
    flex-direction: row;

    > div {
      width: auto;
    }

    > div:not(last-child) {
      margin-right: ${spacing.space8};
      margin-bottom: 0;
    }
  }

  @media (max-width: ${breakpoints.m}) {
    > div {
      box-sizing: border-box;
      width: 100%;
    }
  }
`;
