import React, { FC, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { GridCol, GridRow } from '@naf/grid';
import { ButtonLink } from '@naf/button-link';
import { Text, TextVariant } from '@naf/text';
import { Close } from '@styled-icons/material/Close';
import { StepWizardChildProps } from 'react-step-wizard';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStateMachine } from 'little-state-machine';
import { ProductSelect } from './fieldInputs/Product';
import { WizardNavigation } from '../../../BecomeAMember/components/WizardNavigation';
import useSelector from '../../../../redux/typedHooks';
import { Spinner } from '../../../../components/spinner/Spinner';
import { useQueryParams } from '../../../../hooks/useQueryParameters';
import BlockContent from '../../../../components/block-content/BlockContent';
import { ProductID } from '../../../../lib/ProductID';
import { SummaryProvider } from '../../components/Summary';
import { InternationalDrivingPermitSchema } from '../../schemas/InternationalDrivingPermit';
import { updateAction } from '../../lib/updateAction';
import { buyIdpPath } from '../../../../components/ThemedApp';
import * as S from './styles';

export const defaultProduct = {
  productId: undefined,
  amount: '1',
};

export const DrivingPermit: FC<Partial<StepWizardChildProps>> = ({
  totalSteps,
  currentStep,
  nextStep,
  previousStep,
}) => {
  const {
    actions,
    state: { internationalDrivingPermitForm },
  } = useStateMachine({ updateAction });

  const [hasSelected, setHasSelected] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  const onSubmit = (data: any) => {
    actions.updateAction({ formName: 'internationalDrivingPermitForm', data });
  };

  // Initiate a form context
  const {
    register,
    watch,
    control,
    handleSubmit,
    unregister,
    setError,
    setFocus,
    clearErrors,
    setValue,
    trigger,
    formState,
    formState: { errors },
    reset,
    resetField,
    getValues,
    getFieldState,
  } = useForm({
    resolver: yupResolver(InternationalDrivingPermitSchema),
  });

  const isUpdating = useSelector((state) => Object.values(state.internationalDrivingPermit.isUpdating)[0] || null);

  const queryParameters = useQueryParams();

  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const products = useSelector((state) => state.internationalDrivingPermit.data.eligibleProducts || null);
  const ownedProducts = useSelector((state) => state.internationalDrivingPermit.data.ownedProducts || null);
  const countries = useSelector((state) => state.idpCountries.data);

  const [selectedCountries, setSelectedCountries] = useState<string[]>([]);

  const handleRemoveCountry = (country: string) => {
    setSelectedCountries(selectedCountries.filter((countryName) => countryName !== country));
  };

  const options = countries && countries.map((country) => ({ label: country.countryName, value: country.countryName }));

  const selectCountry = (countryList: typeof countries, value: string) =>
    countryList.find((country) => country.countryName === value);

  // Set the product from query parameters or state if present
  useEffect(() => {
    if (
      products &&
      queryParameters?.product &&
      typeof queryParameters.product !== 'undefined' &&
      products[queryParameters.product]
    ) {
      setValue('product', { productId: queryParameters.product, amount: '1', isAuthenticated });
    } else if (typeof queryParameters?.product === 'undefined') {
      const product = getValues('product');
      if (product) {
        setValue('product', { productId: product.productId, amount: '1', isAuthenticated });
      }
    }
  }, [queryParameters?.product, isAuthenticated, getValues, products, setValue]);

  // Get product selection
  const product = watch('product');

  // Set error if user has made selection but no product is registered
  useEffect(() => {
    if (product && products && hasSelected) {
      if (ownedProducts && (ProductID.Idp1Year && ProductID.Idp3Year && ProductID.Idp1and3Year) in ownedProducts) {
        setError('product', {
          type: 'manual',
          message: 'Du har allerede bestilt Internasjonalt førerkort 1- og 3-årig.',
        });
      } else if (!products[product.productId]) {
        setError('product', {
          type: 'manual',
          message: 'Oops, vi har problemer med motoren... Ta kontakt med kundesenteret hvis problemet fortsetter!',
        });
      } else if (products[product.productId]) clearErrors('product');
    }
  }, [product, products, hasSelected, clearErrors, ownedProducts, setError]);

  // Check if a product is selected
  useEffect(() => {
    if (product && product.productId !== undefined) {
      setHasSelected(true);
    }
  }, [product]);

  if (!products && isUpdating) return <Spinner />;

  return products ? (
    <FormProvider
      register={register}
      watch={watch}
      control={control}
      handleSubmit={handleSubmit}
      unregister={unregister}
      setError={setError}
      setFocus={setFocus}
      clearErrors={clearErrors}
      setValue={setValue}
      trigger={trigger}
      formState={formState}
      getValues={getValues}
      reset={reset}
      resetField={resetField}
      getFieldState={getFieldState}
    >
      <GridRow>
        <GridCol s="12" m="12" l="8" xl="8">
          <form onSubmit={handleSubmit(onSubmit)}>
            <S.Ingress>
              <S.NoMarginText variant={TextVariant.Header3}>Velg type førerkort</S.NoMarginText>
              <S.NoMarginText>
                Hvilket førerkort som er påkrevd eller anbefalt varierer avhengig av hvilket land du skal reise til.
              </S.NoMarginText>
            </S.Ingress>
            <S.InlineLabel htmlFor="productField">
              <Controller
                name="product"
                control={control}
                defaultValue={
                  (internationalDrivingPermitForm && internationalDrivingPermitForm.product) || defaultProduct
                }
                render={ProductSelect}
              />
            </S.InlineLabel>
            {product && products[product.productId]
              ? products[product.productId].productDescription &&
                products[product.productId].productDescription.body && (
                  <S.StyledBlock>
                    <BlockContent value={products[product.productId].productDescription.body.slice(0, 2)} />
                  </S.StyledBlock>
                )
              : products[ProductID.Idp1Year] &&
                products[ProductID.Idp1Year].productDescription.body && (
                  <S.StyledBlock>
                    <BlockContent value={products[ProductID.Idp1Year].productDescription.body.slice(0, 2)} />
                  </S.StyledBlock>
                )}
            <S.StyledSelect
              options={options}
              placeholder="Velg land"
              handleSelect={(option: Record<string, string>) =>
                setSelectedCountries([...selectedCountries, option.label])
              }
              maxLines={6}
            />
            {countries && selectedCountries && selectedCountries.length > 0 && (
              <S.CountryAdditionalInfo>
                {selectedCountries.map(
                  (country) =>
                    selectCountry(countries, country) && (
                      <S.CountryWrapper key={country}>
                        <S.TextWrapper>
                          <Text variant={TextVariant.ArticleTextHeader}>{country}</Text>
                          <Text>{selectCountry(countries, country)?.additionalInfo}</Text>
                        </S.TextWrapper>
                        <S.CloseButton onClick={() => handleRemoveCountry(country)}>
                          <Close size={18} />
                        </S.CloseButton>
                      </S.CountryWrapper>
                    ),
                )}
              </S.CountryAdditionalInfo>
            )}
            {product && products[product.productId]
              ? products[product.productId].productDescription &&
                products[product.productId].productDescription.body && (
                  <S.StyledBlock>
                    <BlockContent value={products[product.productId].productDescription.body.slice(2)} />
                  </S.StyledBlock>
                )
              : products[ProductID.Idp1Year] &&
                products[ProductID.Idp1Year].productDescription.body && (
                  <S.StyledBlock>
                    <BlockContent value={products[ProductID.Idp1Year].productDescription.body.slice(2)} />
                  </S.StyledBlock>
                )}
          </form>
        </GridCol>
        {isMounted && (
          <GridCol s="12" m="12" l="4" xl="4" justify={{ s: 'stretch', m: 'stretch', l: 'end', xl: 'end' }}>
            <SummaryProvider control={control} />
            {!isAuthenticated && (
              <S.LogIn>
                <ButtonLink
                  onClick={() => loginWithRedirect({ appState: { returnTo: buyIdpPath } })}
                  text="Er du medlem? Logg inn og få rabatt"
                />
              </S.LogIn>
            )}
          </GridCol>
        )}
        <S.ResponsiveCol s="12" m="12" l="12" xl="12">
          <S.Line />
        </S.ResponsiveCol>
        <GridCol s="12" m="12" l="12" xl="12">
          <WizardNavigation
            totalSteps={totalSteps}
            currentStep={currentStep}
            nextStep={nextStep}
            previousStep={previousStep}
            isValid={Object.values(errors).length === 0 && hasSelected}
            onSubmit={onSubmit}
          />
        </GridCol>
      </GridRow>
    </FormProvider>
  ) : (
    <Spinner />
  );
};
