import { produce } from 'immer';
import { FormNames } from '../../../../../types/formNames';

export const updateAction = (
  state: Record<keyof FormNames, any>,
  payload: { formName: keyof FormNames; data: Record<string, any> },
): Record<keyof FormNames, any> =>
  produce(state, (draftState) => {
    draftState[payload.formName] = payload.data;
  });
