export const createServerFieldMappings = (
  numberOfProducts: number,
): Record<'form1' | 'form2', Record<string, string>> =>
  [...Array(numberOfProducts || 0).keys()].reduce(
    (acc: Record<'form1' | 'form2', Record<string, string>>, cur) => {
      if (cur === 0) {
        acc.form1[`CustomerProductsRequests[${cur}].Products`] = 'product';
        acc.form2[`CustomerProductsRequests[${cur}].Profile.FirstName`] = 'firstName';
        acc.form2[`CustomerProductsRequests[${cur}].Profile.LastName`] = 'lastName';
        acc.form2[`CustomerProductsRequests[${cur}].Profile.DateOfBirth`] = 'birthDate';
        acc.form2[`CustomerProductsRequests[${cur}].Profile.Email`] = 'eMail';
        acc.form2[`CustomerProductsRequests[${cur}].Profile.PhoneNumber`] = 'phoneNumber';
        acc.form2[`CustomerProductsRequests[${cur}].Profile.Address`] = 'address';
        acc.form2[`CustomerProductsRequests[${cur}].Profile.PostalCode`] = 'zip';
        acc.form2[`CustomerProductsRequests[${cur}].Profile.City`] = 'city';
        acc.form2[`CustomerProductsRequests[${cur}].Profile.Gender`] = 'gender';
      }
      return acc;
    },
    { form1: {}, form2: {} },
  );
