import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import StepWizard, { StepWizardChildProps } from 'react-step-wizard';
import { useDispatch } from 'react-redux';
import { Grid, GridCol } from '@naf/grid';
import { spacing, breakpoints } from '@naf/theme';
import { Text, TextVariant } from '@naf/text';
import { Progressbar } from '../BecomeAMember/components/ProgressBar';
import { Redirect } from '../BecomeAMember/forms/Redirect';
import { DrivingPermit } from './forms/drivingPermit';
import { Holder } from './forms/holder';
import { DEFAULT_IMG_URL } from '../../lib/constants';
import { LayoutWithMainContent } from '../../components/layout/Layout';
import { useAuth0Token } from '../../hooks/useAuth0Token';
import { actions as IdpActions } from '../../redux/modules/internationalDrivingPermit';
import { actions as idpCountriesAction } from '../../redux/modules/idpCountries';
import { useFetchProfile } from '../../hooks/useFetchProfile';
import { useWindowLocation } from '../../hooks/useWindowLocation';
import { InternalSearchCardType } from '../../components/layout/InternalSearchMetaData';

interface Step {
  step: FC<Partial<StepWizardChildProps>> | string;
  name: string;
  type: 'FORM' | 'REDIRECT';
}

export const BuyInternationalDrivingPermit = () => {
  const dispatch = useDispatch();
  const { simpleToken } = useAuth0Token();

  const { profile } = useFetchProfile({ token: simpleToken });

  useEffect(() => {
    dispatch(IdpActions.getInternationalDrivingPermit.request(simpleToken));
  }, [dispatch, simpleToken]);

  useEffect(() => {
    dispatch(idpCountriesAction.getIdpCountries.request(undefined, undefined));
  }, [dispatch]);

  const windowLocation = useWindowLocation();
  const steps: Step[] = [
    { step: DrivingPermit, name: 'Type førerkort', type: 'FORM' },
    { step: Holder, name: 'Personalia', type: 'FORM' },
    { step: 'https://www.naf.no', name: 'Betaling', type: 'REDIRECT' },
  ];

  return (
    <LayoutWithMainContent
      title="Bestill Internasjonalt førerkort"
      description="Her kan du bestille internasjonalt førerkort"
      url={windowLocation}
      imgUrl={DEFAULT_IMG_URL}
      gtmArgs={{
        page_type: 'Bestill Internasjonalt førerkort',
        pageCategory: '',
        contentId: 'buy-internation-driving-permit',
      }}
      internalSearchMetaData={{
        cardType: InternalSearchCardType.Simple,
      }}
    >
      <Grid>
        <GridCol s="12" m="12" l="12" xl="12">
          <Heading variant={TextVariant.Header1}>Bestill Internasjonalt førerkort</Heading>
        </GridCol>
        <GridCol s="12" m="12" l="12" xl="12">
          <StepWizard transitions={{}} isLazyMount nav={<Progressbar steps={steps} />}>
            <DrivingPermit />
            <Holder profile={profile} />
            <Redirect />
          </StepWizard>
        </GridCol>
      </Grid>
    </LayoutWithMainContent>
  );
};

const Heading = styled(Text)`
  margin: ${spacing.space64} 0 ${spacing.space16};

  @media (max-width: ${breakpoints.m}) {
    margin: ${spacing.space32} 0 0;
  }
`;
