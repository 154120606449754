import styled from 'styled-components';
import { Text } from '@naf/text';
import { spacing, breakpoints } from '@naf/theme';
import { Input, DateWrapper } from '@naf/input';
import { GridCol } from '@naf/grid';

const Heading = styled(Text)`
  margin: 0;
  margin-bottom: ${spacing.space8};
`;

const NoMarginText = styled(Text)`
  margin: 0;
`;

const ControllersWrapper = styled.div`
  max-width: 544px;

  @media (max-width: ${breakpoints.m}) {
    max-width: 100%;

    > div {
      width: 100%;
    }
  }
`;

const FieldWrapper = styled.div`
  margin-top: ${spacing.space24};
`;

const FlexWrapper = styled.div`
  display: flex;
  margin-top: ${spacing.space24};
  align-items: flex-end;
  gap: ${spacing.space32};

  @media (max-width: ${breakpoints.l}) {
    flex-direction: column;
    align-items: flex-start;
    vertical-align: top;
    margin-top: 0;
  }
`;

const FirstName = styled.div`
  margin-top: ${spacing.space16};

  @media (min-width: ${breakpoints.m}) {
    margin-right: ${spacing.space32};
    display: inline-flex;
  }
`;

const LastName = styled.div`
  @media (min-width: ${breakpoints.m}) {
    display: inline-flex;
  }
`;

const ResponsiveTextField = styled(Input)`
  @media (max-width: ${breakpoints.m}) {
    width: 100%;
  }
`;

const FullWidthTextField = styled(Input)`
  width: 100%;

  @media (min-width: ${breakpoints.m}) {
    max-width: 544px;
  }
`;

const BirthDateWrapper = styled(DateWrapper)`
  margin-top: -8px;

  > :not(first-child) {
    margin-right: 12px;
  }
`;

const Zip = styled.div`
  @media (min-width: ${breakpoints.m}) {
    margin-right: ${spacing.space32};
    display: inline-flex;
  }
`;

const City = styled.div`
  @media (min-width: ${breakpoints.m}) {
    margin-top: 0;
    display: inline-flex;
  }
`;

const BirthCity = styled.div`
  @media (min-width: ${breakpoints.m}) {
    margin-right: ${spacing.space32};
    display: inline-flex;
  }
`;

const BirthCountry = styled.div`
  @media (min-width: ${breakpoints.m}) {
    margin-top: 0;
    display: inline-flex;
  }
`;

const LogIn = styled.div`
  margin-top: ${spacing.space24};
  display: flex;
  justify-content: flex-end;
`;

const StyledBlock = styled.div`
  margin-top: ${spacing.space64};

  h3,
  p {
    margin: 0;
  }

  h3 {
    margin-bottom: ${spacing.space8};
  }
`;

const ResponsiveCol = styled(GridCol)`
  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

const Line = styled.span`
  height: 1px;
  display: flex;
  background-color: #000;
  opacity: 0.12;
`;

export default {
  Heading,
  NoMarginText,
  ControllersWrapper,
  FieldWrapper,
  FirstName,
  LastName,
  ResponsiveTextField,
  FullWidthTextField,
  BirthDateWrapper,
  Zip,
  City,
  BirthCity,
  BirthCountry,
  LogIn,
  StyledBlock,
  ResponsiveCol,
  Line,
  FlexWrapper,
};
