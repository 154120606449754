import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import React from 'react';
import { Label, Message } from '@naf/input';
import S from '../styles';

export const City = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  errorMessage,
  isDisabled,
}: UseControllerProps<T> & { errorMessage?: string; isDisabled?: boolean }) => (
  <S.City>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange } }) => (
        <S.FieldWrapper>
          <Label>Poststed</Label>
          <S.ResponsiveTextField
            error={!!errorMessage}
            message={errorMessage}
            onChange={onChange}
            value={value}
            autoComplete="adress-level2"
            disabled={isDisabled}
          />
          {!!errorMessage && <Message error>{errorMessage}</Message>}
        </S.FieldWrapper>
      )}
    />
  </S.City>
);
