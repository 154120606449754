import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import React from 'react';
import { Label, Message } from '@naf/input';
import S from '../styles';

export const Email = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  errorMessage,
  isDisabled,
}: UseControllerProps<T> & { errorMessage?: string; isDisabled?: boolean }) => (
  <Controller
    name={name}
    control={control}
    defaultValue={defaultValue}
    render={({ field: { value, onChange } }) => (
      <div>
        <Label>E-post</Label>
        <S.FullWidthTextField
          error={!!errorMessage}
          onChange={onChange}
          value={value}
          autoComplete="email"
          disabled={isDisabled}
        />
        {!!errorMessage && <Message error>{errorMessage}</Message>}
      </div>
    )}
  />
);
