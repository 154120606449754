import * as yup from 'yup';

export const InternationalDrivingPermitSchema = yup.object().shape({
  product: yup
    .object()
    .shape({
      productId: yup.string().required(),
      amount: yup.string().required(),
    })
    .required('Du må velge et produkt!'),
});
