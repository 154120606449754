import React, { useCallback, useEffect, useState } from 'react';
import { Text, TextVariant } from '@naf/text';
import { Tooltip } from '@naf/parts';
import { UseFormReturn } from 'react-hook-form';
import { Toggle, LabelVariant } from '@naf/toggle';
import { ConsentSelect } from '../../../../components/consents/Consent';
import { CommunicationCheck } from '../../../../components/consents/Communication';
import { IdpCustomerFormType } from '../../../../../../types/idpCustomerFormType';
import { ConsentObject, ConsentsData } from '../../../../../../types/consentType';
import { useFetchConsents } from '../../../../hooks/useFetchConsents';
import { HolderFormType } from '../../../../../../types/internationalDrivingPermitFormType';
import S from '../../../../components/my-profile/Styles';

interface ConsentsProps {
  errors: any;
  customerForm: Partial<IdpCustomerFormType>;
}

export const ConsentsForm = ({
  setValue,
  control,
  clearErrors,
  setError,
  getValues,
  customerForm,
  errors,
}: {
  setValue: UseFormReturn<HolderFormType>['setValue'];
  control: UseFormReturn<HolderFormType>['control'];
  clearErrors: UseFormReturn<HolderFormType>['clearErrors'];
  setError: UseFormReturn<HolderFormType>['setError'];
  getValues: UseFormReturn<HolderFormType>['getValues'];
} & ConsentsProps) => {
  const { consents, isLoading, error } = useFetchConsents({ isNewCustomer: true });
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [communicationErrorState, setCommunicationErrorState] = useState<boolean>(false);
  const [NafConsent, setNafConsent] = useState<boolean>(false);

  const communicationErrorMessage = 'Du må velge minst en kanal!';

  const selectAll = () => {
    const formConsents: ConsentsData[] = getValues('consents');

    if (!isChecked) {
      formConsents.forEach((consent: ConsentsData, index) => {
        clearErrors([`consents.${index}`]);
        setNafConsent(true);
        if (consent.id === '20' || consent.id === '50') {
          setValue(`consents.${index}`, { id: consent.id, consented: false, undetermined: false });
        } else {
          setValue(`consents.${index}`, { id: consent.id, consented: true, undetermined: false });
        }
      });
    }
    if (isChecked) {
      formConsents.forEach((consent: ConsentsData, index) => {
        clearErrors([`consents.${index}`]);
        setValue(`consents.${index}`, { id: consent.id, consented: false, undetermined: false });
        setNafConsent(false);
      });
    }
  };

  const handleToggle = () => {
    setIsChecked(!isChecked);
    selectAll();
  };

  const getChildConsents = useCallback(
    (children: ConsentObject[]) => {
      const formConsents: ConsentsData[] = getValues('consents');
      const childConsents: ConsentsData[] = formConsents.filter((object: ConsentsData) => {
        if (children.some((value) => value && value.consentId === object.id)) {
          return object;
        }
        return null;
      });

      return { childConsents, formConsents };
    },
    [getValues],
  );

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (communicationErrorState) {
      timer = setTimeout(() => {
        setError('root.channelError', { type: 'manual' });
      }, 2000);
    }

    return () => {
      clearErrors('root.channelError');
      clearTimeout(timer);
    };
  }, [communicationErrorState, clearErrors, setError]);

  // If main consent is accepted, user have to choose either sms or email
  const consentValidation = (childConsents: ConsentsData[]) => {
    const hasConsent = childConsents.some((value) => value && value.consented === true);

    if (!hasConsent) {
      setCommunicationErrorState(true);
    } else {
      setCommunicationErrorState(false);
    }
  };

  // Perform check when consent is accepted
  const handleSelectYes = (consent?: ConsentObject) => {
    if (consent && consent.children && consent.children.length > 0) {
      const { childConsents } = getChildConsents(consent.children);

      consentValidation(childConsents);
      setNafConsent(true);
    }
  };

  // Remove child consents and clear errors when parent consent is declined
  const handleSelectNo = (consent?: ConsentObject) => {
    setIsChecked(false);
    if (consent && consent.children && consent.children.length > 0) {
      clearErrors('root.channelError');
      const { childConsents, formConsents } = getChildConsents(consent.children);

      formConsents.forEach((child: ConsentsData, index) => {
        if (childConsents.some((value) => value && value.id === child.id)) {
          setValue(`consents.${index}`, { id: child.id, consented: false, undetermined: false });
        }
      });
      setNafConsent(false);
    }
  };

  // Perform check when consent child is changed
  const checkChildConsents = (consent: ConsentObject) => {
    if (consent.children && consent.children.length > 0) {
      const { childConsents } = getChildConsents(consent.children);

      consentValidation(childConsents);
    }
  };

  // Perform check on initial render of consents
  useEffect(() => {
    if (consents) {
      consents.forEach((consent: ConsentObject) => {
        if (consent.consented && consent.children && consent.children.length > 0) {
          const { childConsents } = getChildConsents(consent.children);

          consentValidation(childConsents);
        }
      });
    }
  }, [consents, getChildConsents]);

  // Initiate index used for consents array in form
  let counter = -1;

  return (
    <S.ContentWrapper>
      <Text variant={TextVariant.Header2} id="consents">
        Samtykker
      </Text>
      {(!consents && !isLoading) ||
        (error && (
          <div>
            <S.ErrorText variant={TextVariant.ArticleText}>Noe gikk galt</S.ErrorText>
          </div>
        ))}
      {consents && !isLoading && (
        <>
          <S.BodyText>Jeg samtykker ‘Ja’ til alle punktene under</S.BodyText>
          <S.ToggleWrapper>
            <Toggle
              name="consent to all"
              checked={isChecked}
              onChange={() => handleToggle()}
              variant={LabelVariant.YesNo}
            />
          </S.ToggleWrapper>
          {/* Solution to only show non membership consent while waiting for updated api response */}
          {consents.map((consent: ConsentObject) => {
            counter += 1;
            if (consent.consentId)
              return (
                <S.ConsentWrapper key={consent.consentId} hidden={consent.consentId === '20'}>
                  <S.ConsentText>
                    <S.BodyText tag="span">
                      {consent.text}
                      {consent.description && <Tooltip text={consent.description} />}
                    </S.BodyText>
                  </S.ConsentText>
                  <ConsentSelect
                    name={`consents.${counter}`}
                    disabled={errors.channelError && consent.children && !consent.children.length}
                    control={control}
                    defaultValue={
                      consent.consentId === '20'
                        ? {
                            id: consent.consentId,
                            consented: false,
                            undetermined: false,
                          }
                        : (customerForm && customerForm.consents && customerForm.consents[counter]) || {
                            id: consent.consentId,
                            consented: false,
                            undetermined: true,
                          }
                    }
                    errorMessage={
                      errors.mainMember?.consents &&
                      (errors.mainMember?.consents[counter]?.message ||
                        errors.mainMember?.consents[counter]?.undetermined?.message)
                    }
                    onSelectNo={() => {
                      if (consent.children && consent.children.length > 0) {
                        handleSelectNo(consent);
                      }
                    }}
                    onSelectYes={() => {
                      if (consent.children && consent.children.length > 0) {
                        handleSelectYes(consent);
                      }
                    }}
                  />
                  {consent.children && consent.children.length > 0 && (
                    <S.ConsentChild $isVisible={NafConsent}>
                      <S.BodyText>{consent.labelChildren}</S.BodyText>
                      {consent.children.map((child: ConsentObject) => {
                        counter += 1;
                        return (
                          <CommunicationCheck<HolderFormType>
                            name={`consents.${counter}`}
                            id={child.consentId as string}
                            disabled={false}
                            control={control}
                            key={child.consentId}
                            label={child.text || ''}
                            defaultValue={
                              (customerForm && customerForm.consents && customerForm.consents[counter]) ||
                              child.consentId
                                ? {
                                    id: child.consentId ? child.consentId : '',
                                    consented: false,
                                    undetermined: false,
                                  }
                                : undefined
                            }
                            error={errors.channelError}
                            checkChildConsents={() => checkChildConsents(consent)}
                          />
                        );
                      })}
                      {errors.channelError && <S.ErrorMessage error>{communicationErrorMessage}</S.ErrorMessage>}
                    </S.ConsentChild>
                  )}
                </S.ConsentWrapper>
              );
            return null;
          })}
        </>
      )}
    </S.ContentWrapper>
  );
};
