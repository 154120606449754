import styled from 'styled-components';
import { Text } from '@naf/text';
import { spacing, breakpoints } from '@naf/theme';
import { GridCol } from '@naf/grid';
import { Select } from '@naf/input';

export const InlineLabel = styled.label`
  > div {
    box-sizing: border-box;
    max-width: 100%;
  }
`;

export const Ingress = styled.div`
  h3 {
    margin-bottom: ${spacing.space8};
  }
`;

export const NoMarginText = styled(Text)`
  margin: 0;
`;

export const StyledBlock = styled.div`
  margin-top: ${spacing.space64};

  h3 {
    margin: 0;
    margin-bottom: ${spacing.space8};
  }

  p {
    margin: 0;
  }
`;

export const StyledSelect = styled(Select)`
  margin-top: ${spacing.space24};
`;

export const Line = styled.span`
  height: 1px;
  display: flex;
  background-color: #000;
  opacity: 0.12;
`;

export const ResponsiveCol = styled(GridCol)`
  @media (max-width: ${breakpoints.m}) {
    display: none;
  }
`;

export const LogIn = styled.div`
  margin-top: ${spacing.space24};
  justify-content: flex-end;
`;

export const CountryAdditionalInfo = styled.div`
  margin: 0;
  margin-top: ${spacing.space24};
  display: flex;
  flex-direction: column;
`;

export const CountryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: ${spacing.space24};
  padding-bottom: ${spacing.space24};
  border-bottom: 1px solid #e0e0e0;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${spacing.space40};

  h5,
  p {
    margin: 0;
  }
`;

export const CloseButton = styled.button`
  /* reset style */
  border: none;
  margin: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  touch-action: manipulation;
  appearance: none !important;

  /* style */
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  margin-top: -7px;
  padding: ${spacing.space12};
  height: fit-content;
`;
