import type { CustomerProductsRequestType, OrderType } from '@dtp/membership-service-types';
import { IdpCustomerFormType } from '../../../../../types/idpCustomerFormType';
import { InternationalDrivingPermitFormType } from '../../../../../types/internationalDrivingPermitFormType';

export const createOrderPayload = (
  customerData: Partial<IdpCustomerFormType>,
  productData: Partial<InternationalDrivingPermitFormType>,
  returnUrl: string,
  campaignCode?: string,
  customerId?: string,
): OrderType => {
  const customer: CustomerProductsRequestType = {
    profile: {
      isMainHouseholdMember: true,
      firstName: customerData.firstName,
      lastName: customerData.lastName,
      email: customerData.eMail,
      phoneNumber: customerData.phoneNumber ? `+47${customerData.phoneNumber}` : null,
      dateOfBirth: `${customerData.birthDate?.year.toFixed(0)}-${customerData.birthDate?.month
        .toFixed(0)
        .padStart(2, '0')}-${customerData.birthDate?.day.toFixed(0).padStart(2, '0')}`, // 2021-06-10
      gender: customerData.gender ? customerData.gender : '',
      address: customerData.address,
      postalCode: customerData.zip,
      cityOfBirth: customerData.cityOfBirth,
      countryOfBirth: customerData.countryOfBirth,
    },
    consents:
      customerData?.consents?.map((consent) => ({
        consentNumber: consent.id,
        consented: consent.consented || false,
      })) || [],
    products: productData.product?.productId
      ? [
          {
            productId: productData.product?.productId.toString(),
            isHouseholdProduct: false,
          },
        ]
      : [],
  };
  if (customerId) {
    customer.customerId = customerId;
  }

  const result: OrderType = {
    customerProductsRequests: [customer],
    salesAgent: 'innmelding@naf.no',
    salesChannel: 'web',
    salesLocationNumber: '86646379',
    returnUrl,
  };

  if (campaignCode) {
    result.campaignCode = campaignCode;
  }

  return result;
};
