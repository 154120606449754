import * as yup from 'yup';
import { isDigitsOnly } from '../lib/isDigitsOnly';
import { isBelowLength } from '../lib/isBelowLength';

export const HolderSchema = yup.object().shape({
  firstName: yup.string().required('Du må skrive inn fornavn!'),
  lastName: yup
    .string()
    .test('Lengde', 'Må være kortere enn 50 tegn', isBelowLength(50))
    .required('Du må skrive inn etternavn!'),
  eMail: yup.string().email('Dette er ikke en gyldig e-post').required('Du må skrive inn e-post!'),
  phoneNumber: yup.string().max(8, 'Ikke et gyldig telefonnummer').required('Du må skrive inn telefonnummer!'),
  birthDate: yup
    .object()
    .shape({
      day: yup.number().min(1, 'For lavt').max(31, 'For høyt').required('Dato').typeError('Bruk tall'),
      month: yup.number().min(1, 'For lavt').max(12, 'For høyt').required('Måned').typeError('Bruk tall'),
      year: yup.number().min(1753, 'Det var tidlig..').required('År').typeError('Bruk tall'),
    })
    .typeError('Du må skrive inn fødselsdato!'),
  address: yup.string().required('Du må skrive inn adresse!'),
  zip: yup
    .string()
    .length(4, 'Dette er ikke et gyldig postnummer')
    .test('Kun tall', 'Feltet kan kun inneholde tall', isDigitsOnly)
    .required('Du må skrive inn postnummer!'),
  city: yup.string().required('Du må skrive inn poststed!'),
  cityOfBirth: yup.string().required('Du må skrive inn fødeby!'),
  countryOfBirth: yup.string().required('Du må skrive inn fødeland!'),
  consents: yup.array().of(
    yup
      .object()
      .shape({
        id: yup.string().required(),
        consented: yup.boolean().required('Du må ta stilling til denne!'),
        undetermined: yup
          .boolean()
          .oneOf([false], 'Du må ta stilling til denne!')
          .required('Du må ta stilling til denne!'),
      })
      .typeError('Du må ta stilling til denne!'),
  ),
});
