import React from 'react';
import { UserInteractionVariant } from '@naf/user-interaction';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { FieldValidationAction } from '@dtp/membership-service-types/dist/form/FieldValidationActionTypes';
import useSelector from '../../../redux/typedHooks';
import S from '../../BecomeAMember/components/AlreadyAMemberButton/Styles';

interface Props {
  path: string;
}

export const AlreadyAMemberButton = ({ path }: Props) => {
  const validationMap = useSelector((state) => state.order.validation?.validationMap);
  const { loginWithRedirect } = useAuth0();
  const history = useHistory();
  const error = validationMap && validationMap['CustomerProductsRequests[0].Profile.Email'];

  let ctaText = '';
  let ctaAction: () => void;

  if (error?.action === FieldValidationAction.LoginCreateMembership) {
    ctaText = 'Logg inn og bli medlem';
    ctaAction = async () => {
      await loginWithRedirect({ appState: { returnTo: '/bli-medlem' } });
    };
  }

  if (error?.action === FieldValidationAction.LoginMyMembership) {
    ctaText = 'Logg inn';
    ctaAction = async () => {
      await loginWithRedirect({ appState: { returnTo: path } });
    };
  }

  if (error?.action === FieldValidationAction.LoggedInIsMember) {
    ctaText = 'Prøv igjen';
    ctaAction = async () => {
      history.push(path);
    };
  }

  return (
    (error && (
      <S.StyledUserInteraction
        button={{
          onClick: () => {
            ctaAction();
          },
          children: ctaText,
        }}
        title="Deg har vi sett før"
        variant={UserInteractionVariant.Info}
      >
        {error.message}
      </S.StyledUserInteraction>
    )) ||
    null
  );
};
