import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import React from 'react';
import { Label, Message, Phone } from '@naf/input';
import S from '../styles';

export const PhoneNumber = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  errorMessage,
  isDisabled,
}: UseControllerProps<T> & { errorMessage?: string; isDisabled?: boolean }) => (
  <S.BirthCountry>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange } }) => (
        <S.FieldWrapper>
          <Label>Telefon</Label>
          <Phone
            error={!!errorMessage}
            onChange={(phone: Record<string, string>) => {
              onChange(phone.number.trim());
            }}
            autoComplete="tel"
            value={value}
            disabled={isDisabled}
            countries={[{ name: 'Norge', code: '47' }]}
            selectedCountry={{ name: 'Norge', code: '47' }}
          />
          {!!errorMessage && <Message error>{errorMessage}</Message>}
        </S.FieldWrapper>
      )}
    />
  </S.BirthCountry>
);
