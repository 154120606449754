import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import React from 'react';
import { Label, Message } from '@naf/input';
import S from '../styles';

interface Props {
  defaultValue: any;
  errorMessage?: string;
  isDisabled?: boolean;
}

export const FirstName = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  errorMessage,
  isDisabled,
}: { control: UseControllerProps<T>['control']; name: UseControllerProps<T>['name'] } & Props) => (
  <S.FirstName>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={({ field: { value, onChange } }) => (
        <S.FieldWrapper>
          <Label>Fornavn og mellomnavn</Label>
          <S.ResponsiveTextField
            error={!!errorMessage}
            onChange={onChange}
            value={value}
            autoComplete="given-name"
            disabled={isDisabled}
          />
          {!!errorMessage && <Message error>{errorMessage}</Message>}
        </S.FieldWrapper>
      )}
    />
  </S.FirstName>
);
