import { Controller, FieldValues, UseControllerProps } from 'react-hook-form';
import React from 'react';
import { Label, Message } from '@naf/input';
import S from '../styles';

export const BirthCountry = <T extends FieldValues>({
  name,
  control,
  defaultValue,
  errorMessage,
  isDisabled,
}: UseControllerProps<T> & { errorMessage?: string; isDisabled?: boolean }) => (
  <S.BirthCountry>
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { value, onChange } }) => (
        <S.FieldWrapper>
          <Label>Fødeland</Label>
          <S.ResponsiveTextField error={!!errorMessage} onChange={onChange} value={value} disabled={isDisabled} />
          {!!errorMessage && <Message error>{errorMessage}</Message>}
        </S.FieldWrapper>
      )}
    />
  </S.BirthCountry>
);
