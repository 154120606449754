import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { GridCol, GridRow } from '@naf/grid';
import { TextVariant } from '@naf/text';
import { Label, Message } from '@naf/input';
import { ButtonLink } from '@naf/button-link';
import { StepWizardChildProps } from 'react-step-wizard';
import { FieldPath, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStateMachine } from 'little-state-machine';
import cloneDeep from 'lodash/cloneDeep';
import type { ProfileForm } from '@dtp/membership-service-types';
import { WizardNavigation } from '../../../BecomeAMember/components/WizardNavigation';
import { updateAction } from '../../lib/updateAction';
import S from './styles';
import { FirstName } from './fieldInputs/FirstName';
import { LastName } from './fieldInputs/LastName';
import { Email } from './fieldInputs/Email';
import { BirthDateDay } from '../../../../components/Form/fieldInputs/BirthDateDay';
import { BirthDateMonth } from '../../../../components/Form/fieldInputs/BirthDateMonth';
import { BirthDateYear } from '../../../../components/Form/fieldInputs/BirthDateYear';
import { ZipCode } from './fieldInputs/Zip';
import { City } from './fieldInputs/City';
import { BirthCity } from './fieldInputs/BirthCity';
import { BirthCountry } from './fieldInputs/BirthCountry';
import { actions as orderActions } from '../../../../redux/modules/order';
import useSelector from '../../../../redux/typedHooks';
import { Summary } from '../../components/Summary';
import { HolderSchema } from '../../schemas/HolderSchema';
import { ConsentsForm } from './ConsentsForm';
import { createOrderPayload } from '../../lib/createOrderPayload';
import { useQueryParams } from '../../../../hooks/useQueryParameters';
import { useAuth0Token } from '../../../../hooks/useAuth0Token';
import { IdpCustomerFormType } from '../../../../../../types/idpCustomerFormType';
import { useFetchCity } from '../../../../hooks/useFetchCity';
import BlockContent from '../../../../components/block-content/BlockContent';
import { createServerFieldMappings } from '../../lib/createServerFieldMappings';
import { useSendGTMEventOnce } from '../../../../hooks/useSendGTMEventOnce';
import { NAFGTMEvent } from '../../../../../../types/GTM/NAFGTMEvent';
import { AlreadyAMemberButton } from '../../components/AlreadyAMemberButton';
import { HolderFormType } from '../../../../../../types/internationalDrivingPermitFormType';
import { PhoneNumber } from './fieldInputs/PhoneNumber';
import { Address } from '../../../../components/Address';
import { buyIdpPath } from '../../../../components/ThemedApp';

// Define the form - receives props from wizard
export const Holder: FC<
  Partial<
    StepWizardChildProps & {
      profile?: ProfileForm;
    }
  >
> = ({ totalSteps, currentStep, nextStep, previousStep, goToStep, profile }) => {
  const dispatch = useDispatch();
  const [profileIsLoaded, setProfileIsLoaded] = useState(false);
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const serverErrors = useSelector((state) => state.order.validation?.validationResults);
  const { baseUrl } = useSelector((state) => state.application);
  const { customerInformation } = useSelector((state) => state.myMembership);
  const { simpleToken } = useAuth0Token();

  const queryParams = useQueryParams();

  // Get current form state from session storage
  const {
    actions,
    state: { internationalDrivingPermitForm, idpCustomerForm },
  } = useStateMachine({ updateAction });

  const product = useSelector(
    (state) =>
      (internationalDrivingPermitForm?.product?.productId &&
        state.internationalDrivingPermit.data.eligibleProducts &&
        state.internationalDrivingPermit.data.eligibleProducts[internationalDrivingPermitForm?.product?.productId]) ||
      undefined,
  );

  // Function submitting form data to local storage
  const onSubmit = (data: any) => {
    const copiedData = cloneDeep(data);
    actions.updateAction({ formName: 'idpCustomerForm', data: copiedData });
    if (data) {
      dispatch(
        orderActions.createOrder.request(
          createOrderPayload(
            data,
            internationalDrivingPermitForm,
            `${baseUrl}/kvittering-internasjonalt-forerkort`,
            queryParams?.campaignCode,
            customerInformation.data.customerId,
          ),
          { token: simpleToken },
        ),
      );
    }
  };

  // Function submitting form data to local storage
  const onGoBack = (data: IdpCustomerFormType) => {
    const copiedData = cloneDeep(data);
    actions.updateAction({ formName: 'idpCustomerForm', data: copiedData });
  };

  // Initiate a form context
  const {
    register,
    watch,
    control,
    handleSubmit,
    unregister,
    setError,
    setFocus,
    clearErrors,
    setValue,
    trigger,
    formState,
    formState: { errors },
    reset,
    resetField,
    getValues,
    getFieldState,
  } = useForm<HolderFormType>({
    resolver: yupResolver(HolderSchema),
    mode: 'onChange',
  });

  const zipCode = getValues('zip');
  const { cityName, getCity, cityError } = useFetchCity(zipCode);

  useEffect(() => {
    if (zipCode && zipCode.length === 4) {
      getCity();
    }
  }, [zipCode, getCity]);

  useEffect(() => {
    if (cityName && !cityError) {
      setValue('city', cityName);
      clearErrors('city');
    }
    if (cityName && cityError) {
      setValue('city', cityName);
      setError('city', {
        type: 'manual',
        message: 'Vi klarte ikke oppdatere poststed. Kontakt kundeservice',
      });
    }
  }, [cityName, cityError, clearErrors, setValue, setError]);

  // Check if a product has been selected, if not, go back to step 1
  useEffect(() => {
    if (internationalDrivingPermitForm && !internationalDrivingPermitForm.product && goToStep) {
      goToStep(1);
    }
  }, [internationalDrivingPermitForm, goToStep]);
  useEffect(() => {
    if (profile && !profileIsLoaded && setProfileIsLoaded) {
      if (profile?.phoneNumber?.value)
        setValue('phoneNumber', idpCustomerForm?.phoneNumber || profile?.phoneNumber.value);
      if (profile?.firstName?.value) setValue('firstName', idpCustomerForm?.firstName || profile?.firstName.value);
      if (profile?.lastName?.value) setValue('lastName', idpCustomerForm?.lastName || profile?.lastName.value);
      if (profile?.email?.value) setValue('eMail', idpCustomerForm?.eMail || profile?.email.value);
      if (profile?.dateOfBirth?.value)
        setValue('birthDate.day', idpCustomerForm?.birthDate?.day || new Date(profile?.dateOfBirth.value).getDate());
      if (profile?.dateOfBirth?.value)
        setValue(
          'birthDate.month',
          idpCustomerForm?.birthDate?.month || new Date(profile?.dateOfBirth.value).getMonth() + 1,
        );
      if (profile?.dateOfBirth?.value)
        setValue(
          'birthDate.year',
          idpCustomerForm?.birthDate?.year || new Date(profile?.dateOfBirth.value).getFullYear(),
        );
      if (profile?.gender?.value) setValue('gender', idpCustomerForm?.gender || profile?.gender.value);
      if (profile?.address?.value) setValue('address', idpCustomerForm?.address || profile?.address.value);
      if (profile?.postalCode?.value) setValue('zip', idpCustomerForm?.zip || profile?.postalCode.value);
      if (profile?.city?.value) setValue('city', idpCustomerForm?.city || profile?.city.value);
      setProfileIsLoaded(true);
    }
  }, [
    profile,
    setProfileIsLoaded,
    profileIsLoaded,
    idpCustomerForm?.address,
    idpCustomerForm?.birthDate?.day,
    idpCustomerForm?.birthDate?.month,
    idpCustomerForm?.birthDate?.year,
    idpCustomerForm?.city,
    idpCustomerForm?.eMail,
    idpCustomerForm?.firstName,
    idpCustomerForm?.gender,
    idpCustomerForm?.lastName,
    idpCustomerForm?.phoneNumber,
    idpCustomerForm?.zip,
    setValue,
  ]);

  const numberOfProducts = useMemo(
    () =>
      internationalDrivingPermitForm?.product?.amount && parseInt(internationalDrivingPermitForm?.product?.amount, 10),
    [internationalDrivingPermitForm?.product?.amount],
  );
  // Map server field errors to fields in frontend
  useEffect(() => {
    const mapping = typeof numberOfProducts === 'number' ? createServerFieldMappings(numberOfProducts) : undefined;
    if (serverErrors) {
      serverErrors.forEach((error) => {
        // Check if error is of user type
        if (mapping && Object.keys(mapping.form2).includes(error.field)) {
          setError(mapping.form2[error.field] as FieldPath<HolderFormType>, { type: 'server', message: error.message });
        }
      });
    }
  }, [serverErrors, numberOfProducts, setError]);
  useSendGTMEventOnce(
    product && typeof internationalDrivingPermitForm?.product?.amount === 'number'
      ? {
          event: NAFGTMEvent.addShippingInfo,
          ecommerce: {
            items: [
              {
                item_id: product.productNumber,
                quantity: internationalDrivingPermitForm?.product?.amount,
                item_name: product.productName,
                price:
                  internationalDrivingPermitForm &&
                  internationalDrivingPermitForm.product &&
                  (
                    product.productAppPrice[internationalDrivingPermitForm?.product?.amount].itemTotalPrice /
                    internationalDrivingPermitForm.product.amount
                  ).toFixed(2),
                item_category2: 'Internasjonalt Førerkort',
                item_category3: 'Bestill',
              },
            ],
          },
        }
      : undefined,
    [product, internationalDrivingPermitForm?.product?.amount],
  );

  useSendGTMEventOnce(
    {
      event: NAFGTMEvent.checkoutFunnel,
      checkout_step: 2,
    },
    [internationalDrivingPermitForm?.product?.productId, internationalDrivingPermitForm?.product?.amount],
  );
  return (
    <FormProvider
      register={register}
      watch={watch}
      control={control}
      handleSubmit={handleSubmit}
      unregister={unregister}
      setError={setError}
      setFocus={setFocus}
      clearErrors={clearErrors}
      setValue={setValue}
      trigger={trigger}
      formState={formState}
      getValues={getValues}
      reset={reset}
      resetField={resetField}
      getFieldState={getFieldState}
    >
      <GridRow>
        <GridCol s="12" m="12" l="8" xl="8">
          <form onSubmit={handleSubmit(onSubmit)}>
            <S.Heading variant={TextVariant.Header3}>Personalia</S.Heading>
            <S.NoMarginText>
              For å kunne gjennomføre bestilling av internasjonalt førerkort må du har førerkort registrert i
              Norge/EU/EØS, og ha folkeregistrert adresse i Norge.
            </S.NoMarginText>
            <S.ControllersWrapper>
              <FirstName
                name="firstName"
                isDisabled={profile?.firstName?.editable === false || false}
                defaultValue={idpCustomerForm && idpCustomerForm.firstName}
                errorMessage={errors.firstName?.message}
                control={control}
              />
              <LastName
                name="lastName"
                isDisabled={profile?.lastName?.editable === false || false}
                defaultValue={(idpCustomerForm && idpCustomerForm.lastName) || ''}
                errorMessage={errors.lastName?.message}
                control={control}
              />
            </S.ControllersWrapper>
            <S.FieldWrapper>
              <Email
                name="eMail"
                isDisabled={profile?.email?.editable === false || false}
                defaultValue={(idpCustomerForm && idpCustomerForm.eMail) || ''}
                control={control}
                errorMessage={errors.eMail?.type !== 'server' ? errors.eMail?.message : undefined}
              />
              {errors.eMail?.type === 'server' && <AlreadyAMemberButton path={buyIdpPath} />}
            </S.FieldWrapper>
            <S.FlexWrapper>
              <PhoneNumber
                name="phoneNumber"
                defaultValue={(idpCustomerForm && idpCustomerForm.phoneNumber) || ''}
                control={control}
                errorMessage={errors.phoneNumber?.type !== 'server' ? errors.phoneNumber?.message : undefined}
              />
              <S.BirthDateWrapper as="div">
                <Label>Fødselsdato</Label>
                <BirthDateDay
                  name="birthDate.day"
                  isDisabled={profile?.dateOfBirth?.editable === false || false}
                  control={control}
                  defaultValue={(idpCustomerForm && idpCustomerForm.birthDate?.day) || ''}
                  errorMessage={errors.birthDate?.day?.message}
                  clearError={() => clearErrors('birthDate')}
                />
                <BirthDateMonth
                  name="birthDate.month"
                  isDisabled={profile?.dateOfBirth?.editable === false || false}
                  control={control}
                  defaultValue={(idpCustomerForm && idpCustomerForm.birthDate?.month) || ''}
                  errorMessage={errors.birthDate?.month?.message}
                  clearError={() => clearErrors('birthDate')}
                />
                <BirthDateYear
                  name="birthDate.year"
                  isDisabled={profile?.dateOfBirth?.editable === false || false}
                  control={control}
                  defaultValue={(idpCustomerForm && idpCustomerForm.birthDate?.year) || ''}
                  errorMessage={errors.birthDate?.year?.message}
                  clearError={() => clearErrors('birthDate')}
                />
                {errors.birthDate?.type === 'server' && <Message error>{errors.birthDate?.message}</Message>}
              </S.BirthDateWrapper>
            </S.FlexWrapper>
            <Address
              name="address"
              isDisabled={profile?.address?.editable === false || false}
              control={control}
              defaultValue={(idpCustomerForm && idpCustomerForm.address) || ''}
              errorMessage={errors.address?.message}
              handleChange={(option) => {
                if (option.postnummer) {
                  setValue('zip', option.postnummer, { shouldDirty: true, shouldTouch: true });
                  clearErrors('zip');
                }
                if (option.poststed) {
                  setValue('city', option.poststed, { shouldDirty: true, shouldTouch: true });
                  clearErrors('city');
                }
              }}
              placeholder="Gatenavn og -nummer"
            />
            <S.ControllersWrapper>
              <ZipCode
                name="zip"
                isDisabled={profile?.postalCode?.editable === false || false}
                control={control}
                defaultValue={(idpCustomerForm && idpCustomerForm.zip) || ''}
                errorMessage={errors.zip?.message}
              />
              <City
                name="city"
                isDisabled={profile?.city?.editable === false || false}
                control={control}
                defaultValue={(idpCustomerForm && idpCustomerForm.city) || ''}
                errorMessage={errors.city?.message}
              />
            </S.ControllersWrapper>
            <S.ControllersWrapper>
              <BirthCity
                name="cityOfBirth"
                isDisabled={false}
                control={control}
                defaultValue={(idpCustomerForm && idpCustomerForm.cityOfBirth) || ''}
                errorMessage={errors.cityOfBirth?.message}
              />
              <BirthCountry
                name="countryOfBirth"
                isDisabled={false}
                control={control}
                defaultValue={(idpCustomerForm && idpCustomerForm.countryOfBirth) || ''}
                errorMessage={errors.countryOfBirth?.message}
              />
            </S.ControllersWrapper>
            {!isAuthenticated && !isLoading && (
              <ConsentsForm
                setValue={setValue}
                control={control}
                errors={errors}
                clearErrors={clearErrors}
                setError={setError}
                getValues={getValues}
                customerForm={idpCustomerForm}
              />
            )}
          </form>
          <S.StyledBlock>
            <BlockContent value={product?.productDescription.body.slice(2)} />
          </S.StyledBlock>
        </GridCol>
        <GridCol s="12" m="12" l="4" xl="4" justify={{ s: 'stretch', m: 'stretch', l: 'end', xl: 'end' }}>
          <Summary products={internationalDrivingPermitForm?.product} />
          {!isAuthenticated && (
            <S.LogIn>
              <ButtonLink
                onClick={() => loginWithRedirect({ appState: { returnTo: buyIdpPath } })}
                text="Er du medlem? Logg inn og få rabatt"
              />
            </S.LogIn>
          )}
        </GridCol>
        <S.ResponsiveCol s="12" m="12" l="12" xl="12">
          <S.Line />
        </S.ResponsiveCol>
        <GridCol s="12" m="12" l="12" xl="12">
          <WizardNavigation
            onGoback={onGoBack}
            totalSteps={totalSteps}
            currentStep={currentStep}
            nextStep={nextStep}
            previousStep={previousStep}
            isValid={Object.values(errors).length === 0}
            onSubmit={onSubmit}
          />
        </GridCol>
      </GridRow>
    </FormProvider>
  );
};
